import { useState, useLayoutEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone' // dependent on utc plugin

import { CookieNames } from 'lib/storage/cookies/constants'
import { getParsedCookie } from 'lib/storage/cookies/helpers'
import { PRODUCTS } from 'lib/products/constants'

import { PRODUCT_SUPPORT_MAP } from './constants'

dayjs.extend(utc)
dayjs.extend(timezone)

const useProductSupportStatus = (product: PRODUCTS): boolean => {
  const fallbackSupportInfo = {
    startTime: 9,
    endTime: 19,
    daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    overflow: false,
  }

  const supportInfo = PRODUCT_SUPPORT_MAP[product] || fallbackSupportInfo

  const [isProductSupportOpen, setProductSupportOpen] = useState(true)

  useLayoutEffect(() => {
    // http://browserhacks.com/#hack-acea075d0ac6954f275a70023906050c
    const isIE11 =
      '-ms-scroll-limit' in document.documentElement.style &&
      '-ms-ime-align' in document.documentElement.style

    const timeInLondon = isIE11 ? dayjs().utc() : dayjs().tz('Europe/London')
    const hourInLondon = timeInLondon.hour()
    const isWithinProductSupportHours =
      hourInLondon >= supportInfo.startTime &&
      hourInLondon < supportInfo.endTime

    const isWithinProductSupportDays = supportInfo.daysOfWeek.includes(
      timeInLondon.day()
    )

    setProductSupportOpen(
      supportInfo.overflow ||
        (isWithinProductSupportHours && isWithinProductSupportDays)
    )

    const override = getParsedCookie<boolean>(
      CookieNames.OUT_OF_HOURS_STATE_OVERRIDE
    )
    if (override !== null) setProductSupportOpen(override)
  }, [])

  return isProductSupportOpen
}

export default useProductSupportStatus
