import dayjs from 'dayjs'
import { PRODUCTS } from 'lib/products/constants'
import { PRODUCT_SUPPORT_MAP } from './constants'

export const formatHour = (hour: number): string => {
  if (hour < 12) {
    return `${hour}am`
  }
  return `${hour - 12}pm`
}
export const formatDay = (day: number): string => {
  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  return days[day - 1]
}

// Outputs the open hours of a product's support

export const formattedOpenHours = (PRODUCT: PRODUCTS): string => {
  const support = PRODUCT_SUPPORT_MAP[PRODUCT]
  return `${formatHour(support.startTime)} to ${formatHour(support.endTime)}`
}

// Outputs the open days of a product's support

export const formattedOpenDays = (PRODUCT: PRODUCTS): string => {
  const support = PRODUCT_SUPPORT_MAP[PRODUCT]
  const days = support.daysOfWeek

  if (days.length === 7) return '7 days a week'

  return `${formatDay(days[0])} to ${formatDay(days[days.length - 1])}`
}

export const formatPublishDates = (isoString: string): string =>
  dayjs(isoString).format('D MMMM YYYY')
